import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './config/i18n';
import PageLoader from './shared/PageLoader/PageLoader';

const BusinessApp = React.lazy(() => import('./env-business/App'));
const InterviewerApp = React.lazy(() => import('./env-interviewer/App'));
const PanelistApp = React.lazy(() => import('./env-panelist/App'));
const LandingPageApp = React.lazy(() => import('./env-landing-page/App'));
const AdminApp = React.lazy(() => import('./env-admin/App'));

export default function App() {
	return (
		<Suspense fallback={<PageLoader />}>
			<BrowserRouter basename={'/'}>
				<Routes>
					<Route path={`/business/*`} element={<BusinessApp />} />
					<Route
						path={`/interviewer/*`}
						element={<InterviewerApp />}
					/>
					<Route path={`/panelist/*`} element={<PanelistApp />} />
					<Route path={`/admin/*`} element={<AdminApp />} />
					<Route path={`/*`} element={<LandingPageApp />} />
				</Routes>
			</BrowserRouter>
		</Suspense>
	);
}
