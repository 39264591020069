import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getInitLanguage } from '../helpers/pathname';

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: true,
		interpolation: {
			escapeValue: false,
		},
		lng: getInitLanguage(),
	});
