import {
	AVAILABLE_LANGUAGES,
	PANELIST_AVAIBLE_LANGUAGES,
} from '../config/languages';
import { Language } from '../types/language';

export const checkUrlLang = (pathname) => {
	const basePathanem = pathname.replace(process.env.PUBLIC_URL, '');
	const pathParts = basePathanem.split('/').slice(1);
	const indexApp = pathParts.findIndex(
		(part) => part === 'admin' || 'business' || 'panelist' || 'interviewer'
	);
	if (indexApp === -1) return pathParts[0];

	return pathParts[indexApp + 1];
};

export const isLangSupported = (lang, enableUa = false) => {
	const languages = enableUa
		? PANELIST_AVAIBLE_LANGUAGES
		: AVAILABLE_LANGUAGES;
	return Object.keys(languages).indexOf(lang) !== -1;
};

export const getInitLanguage = (supportedUA = false) => {
	const language = checkUrlLang(window.location.pathname);

	if (!isLangSupported(language, supportedUA)) return Language.pl;
	return language;
};
