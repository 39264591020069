import React from 'react';
import { ReactComponent as Graf1 } from '../../assets/commons/graf-1.svg';
import SpinnerHorizontal from '../SpinnerHorizontal';
import styles from './pageLoader.module.scss';
import { ReactComponent as Logo } from '../../assets/layouts/sidebar/logo.svg';

export default function PageLoader() {
	return (
		<div className={styles.pageLoader}>
			<Logo className={styles.logo} />
			<Graf1 className={styles.image} />
			<SpinnerHorizontal />
		</div>
	);
}
