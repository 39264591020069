import { Language } from '../types/language';

export const LANG_PL = 'pl';
export const LANG_EN = 'en';
export const LANG_UA = 'ua';

export const AVAILABLE_LANGUAGES = {
	[Language.pl]: 'Polski',
	[Language.en]: 'English',
};

export const PANELIST_AVAIBLE_LANGUAGES = {
	[Language.pl]: 'Polski',
	[Language.en]: 'English',
	[Language.ua]: 'український',
};
