import React from 'react';
import spinnerBlue from '../assets/loader/spinner-horizontal_blue.gif';
import spinnerRed from '../assets/loader/spinner-horizontal_red.gif';
import cx from 'classnames';

const SpinnerHorizontal = ({
	isBlue,
	className,
}: {
	isBlue?: boolean;
	className?: string;
}) => {
	return (
		<div className={cx('loader', className)}>
			{isBlue === true ? (
				<img src={spinnerBlue} alt="Loading..." />
			) : (
				<img src={spinnerRed} alt="Loading..." />
			)}
		</div>
	);
};

export default SpinnerHorizontal;
